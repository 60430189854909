import Pagination from "./sbb/Pagination";
import useI18n from "@/lib/useI18n";

export default function AppPagination({ ...props }) {
  const { t } = useI18n();
  return (
    <Pagination
      previousPageText={t("pagination.previousPage")}
      nextPageText={t("pagination.nextPage")}
      {...props}
    />
  );
}
