import { useMemo } from "react";

import { ChevronSmallLeftSmall, ChevronSmallRightSmall } from "./icons";

type PaginationItem = {
  active: boolean;
  label: string;
  pageIndex?: number;
};

export declare interface PaginationProps {
  length?: number;
  onChange?: (pageIndex: number) => void;
  pageIndex?: number;
  pageSize?: number;
  previousPageText?: string;
  nextPageText?: string;
}

export default function Pagination({
  length = 0,
  onChange = () => null,
  pageIndex = 1,
  pageSize = 50,
  previousPageText = "Previous Page",
  nextPageText = "Next Page",
}: PaginationProps) {
  const items = useMemo(() => {
    const newItems: PaginationItem[] = [];
    if (length === 0) {
      return newItems;
    }

    const pageCount = Math.ceil(length / pageSize);
    const offset = pageIndex < pageCount - 2 ? 1 : 3 - (pageCount - pageIndex);
    const startIndex = Math.max(2, pageIndex - offset);
    const endIndex = Math.min(pageCount, pageIndex < 3 ? 5 : pageIndex + 2);

    if (pageCount > 0) {
      newItems.push({ label: "1", pageIndex: 1, active: pageIndex === 1 });
    }

    if (pageCount > 5 && pageIndex > 3) {
      newItems.push({ label: "...", active: false });
    }

    for (let i = startIndex; i < endIndex; i++) {
      newItems.push({
        label: i.toString(),
        pageIndex: i,
        active: pageIndex === i,
      });
    }

    if (pageCount > 5 && pageIndex < pageCount - 2) {
      newItems.push({ label: "...", active: false });
    }

    if (pageCount > 1) {
      newItems.push({
        label: pageCount.toString(),
        pageIndex: pageCount,
        active: pageIndex === pageCount,
      });
    }

    return newItems;
  }, [length, pageIndex, pageSize]);

  return (
    <div className="flex">
      <button
        aria-label={previousPageText}
        className="text-iron hover:text-red125 disabled:cursor-auto disabled:text-iron"
        disabled={items.length === 0 || items.at(0)?.active}
        onClick={() => onChange(pageIndex - 1)}
        type="button"
      >
        <ChevronSmallLeftSmall />
      </button>
      {items.map((item, index) => (
        <button
          className={`min-w-[2rem] rounded-full border-2 leading-7 text-granite hover:text-red125 disabled:cursor-auto disabled:text-granite ${
            item.active ? "border-granite" : "border-white"
          }`}
          disabled={item.active || item.pageIndex === undefined}
          key={index}
          onClick={() =>
            item.pageIndex !== undefined && onChange(item.pageIndex)
          }
        >
          {item.label}
        </button>
      ))}
      <button
        aria-label={nextPageText}
        className="text-iron hover:text-red125 disabled:cursor-auto disabled:text-iron"
        disabled={items.length === 0 || items.at(-1)?.active}
        onClick={() => onChange(pageIndex + 1)}
        type="button"
      >
        <ChevronSmallRightSmall />
      </button>
    </div>
  );
}
