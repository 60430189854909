import { Controller, useFormContext } from "react-hook-form";

import Select, { Option } from "./sbb/Select";
import useI18n from "@/lib/useI18n";

export declare interface Option {
  value: string;
  label: string;
}

export declare interface Props {
  disabled?: boolean;
  filter?: boolean;
  label: string;
  multiple?: boolean;
  name: string;
  onFilterChange?: (value: string) => void;
  options?: Option[];
  required?: boolean;
}

function AppSelect({ filter, multiple, name, options, ...selectProps }: Props) {
  const { control } = useFormContext();
  const { t } = useI18n();

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: selectProps.required ? t("fieldRequired") : false }}
      render={({ field: { value, onChange, ...fieldProps }, fieldState }) => {
        const currentOptions = multiple
          ? options?.filter((o) => value?.includes(o?.value))
          : options?.find((o) => o.value === value);

        return (
          <Select
            error={fieldState.error?.message}
            filter={filter}
            multiple={multiple}
            value={
              Array.isArray(currentOptions)
                ? currentOptions.map((o) => o.label).join(", ")
                : currentOptions?.label
            }
            onChange={(optionValue) => {
              if (multiple) {
                const last = optionValue.at(-1);
                if (optionValue.length === 0) {
                  return onChange([]);
                }
                return value.includes(last)
                  ? onChange(value.filter((v: string) => v !== last))
                  : onChange([...value, last]);
              }
              return onChange(optionValue === undefined ? null : optionValue);
            }}
            pleaseSelectText={t("pleaseSelect")}
            {...fieldProps}
            {...selectProps}
          >
            {options?.map((option) => (
              <Option
                key={option.value}
                selected={
                  Array.isArray(value)
                    ? value?.includes(option.value)
                    : value === option.value
                }
                value={option.value}
              >
                {option.label}
              </Option>
            ))}
          </Select>
        );
      }}
    />
  );
}

export default AppSelect;
